<template>

    <div class="">
        <div class="card-header bg-light "><span><i class="fa fa-pills"> </i> Riesgo Reproductivo</span> </div>
        <div class="card-body">
            <div class="table-responsive mt-2">
                <table class="table table-bordered table-striped">
                    <tr v-for="item in instrumentos.detalles" :key="item.id">
                        <th>{{item.opcion.pregunta.pregunta}}</th>
                        <th>{{item.opcion.respuesta}} (= {{item.opcion.puntuacion}})</th>
                        
                    </tr>
                </table>
                <div class="row col-12"><span>Puntaje: {{instrumentos.puntuacion_total}} </span></div>
            </div>
        </div>
    </div>

</template>
<script>
import instrumentoService from "../../../../services/instrumentoService";
import historiaInstrumentoService from "../../../../services/historiaInstrumentoService";
import {isEmpty} from "lodash";

export default {
    props: ['idHistoria','finalizada'],
    data() {
        return {
           instrumentos:[],
           id_instrumento : '',
           codigo:'riesgo',
           hasStore : false,
           id_instrumento_historia : '',         
        }
    },
    methods: {
        async cargarInstrumentos(){
            const response = await instrumentoService.showByCodigo(this.codigo);
            this.id_instrumento = response.data.id;
            this.instrumentos= response.data.preguntas.map(pregunta => {
                return {
                    ...pregunta,
                    id_opcion : '',
                }
            });
        },
    },
    async created(){

       await this.cargarInstrumentos();

        const response_historia = await historiaInstrumentoService.showByHcI(this.idHistoria,this.id_instrumento);
        if(!isEmpty(response_historia.data)){
            this.instrumentos = response_historia.data;
        }
        
    },
    
}
</script>