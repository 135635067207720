<template>
    <main>
        <div>
            <div class="card-header bg-light "><span><i class="fa fa-syringe"> </i> Revision por Sistemas</span> </div>
            <div class="card-body">
                <div class="form-row">
                    <div class="form-group col-md-12">
                        <table class="table table-striped table-sm table-bordered font-size-12">
                            <tr v-for="item in partes_revision" :key="item.id">
                                <th>{{item.parte_revision.nombre}}</th>
                                <td>{{item.detalle}}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import historiaRevisionSistemasService from "../../../../services/historiaRevisionSistemasService";
import {isEmpty} from 'lodash';

export default {
    props:['idHistoria'],
    data(){
        return {
            partes_revision:[],
        }
    },
    async created(){
        const response = await historiaRevisionSistemasService.showByHc(this.idHistoria);
        if(!isEmpty(response.data)){
            this.partes_revision=response.data;  
        }
    }
}
</script>
<style scoped>
.font-size-12 {
  font-size: 12px;
}
</style>