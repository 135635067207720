<template>
    <main>
        <form>
            <div class="form-row">
                <div class="form-group col-md-12">
                    <table class="table table-bordered table-striped">
                        <tr>
                            <th>Patologia</th>
                            <th></th>
                        </tr>
                        <tr>
                            <td>Hipertension Arterial</td>
                            <td class="text-center align-middle"> 
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" value="Hipertension Arterial" name="radio" v-model="respuesta"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Diabetes</td>
                            <td class="text-center align-middle"> 
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" value="Diabetes" name="radio" v-model="respuesta"/>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td>Hipertension Arterial + Diabetes</td>
                            <td class="text-center align-middle"> 
                                <div class="form-check form-check-inline">
                                    <input class="form-check-input" type="radio" value="Hipertension Arterial + Diabetes" name="radio" v-model="respuesta"/>
                                </div>
                            </td>
                        </tr>
                    </table>
                     <span class="text-danger d-block" v-if="$v.respuesta.$error && $v.respuesta.$dirty">Debe completar el formulario</span>
                </div>
            </div>
        </form>
    </main>
</template>
<script>
import Toast from '../../../../components/common/utilities/toast';
import historiaTipopatologiaService from "../../../../services/historiaTipoPatologiaService";
import {isEmpty} from "lodash";
import {required} from 'vuelidate/src/validators';
export default {
    props:['idHistoria','finalizada'],
    data () {
        return {
            respuesta:'',
            control:false,
            id_historia_patologia:''
        }
    },
    validations () {
        return {
            respuesta : {required}
        }
    },
    methods: {
        async save(){
            try {

                this.$v.$touch();

                if(this.$v.$invalid) return;

                let patologia={
                    id_historia: this.idHistoria,
                    respuesta: this.respuesta
                }

                this.LoaderSpinnerShow();

                if(this.control){
                    patologia['id']=this.id_historia_patologia;
                    await historiaTipopatologiaService.update(patologia);
                    this.control=true;
                }else{
                    await historiaTipopatologiaService.store(patologia);
                    this.control=true;
                }
                    
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            } catch (error) {
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        }
    },
    watch:{
        finalizada: async function (value){
            if(value==='N'){
                 const response = await historiaTipopatologiaService.showByHc(this.idHistoria);
                if(!isEmpty(response.data)){
                    this.control=true;
                    this.respuesta=response.data.respuesta;
                    this.id_historia_patologia=response.data.id;
                }
            } 
        }
    },
    async created(){
        if(this.finalizada==='N'){
            const response = await historiaTipopatologiaService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                this.control=true;
                this.respuesta=response.data.respuesta;
                this.id_historia_patologia=response.data.id;
            }
        }
    }
}
</script>

