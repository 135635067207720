<template>
    <main>
        <div class=" " >
            <div class=" ">
                <div class="form-row">
                    <div class="form-group col-md-4 "  >
                        <label class="form-label" >Metodo utilizado</label>
                        <input type="text" class="form-control" v-model="item.metodo_utilizado" :class="{'is-invalid':$v.item.metodo_utilizado.$error && $v.item.metodo_utilizado.$dirty}">
                    </div>
                    <div class="form-group col-md-4">
                        <label class="form-label">Desde</label>
                        <input type="date" v-model="item.desde" class="form-control" :class="{'is-invalid':$v.item.desde.$error && $v.item.desde.$dirty}">
                    </div>
                    <div class="form-group col-md-4">
                        <label class="form-label">Hasta</label>
                        <input type="date" v-model="item.hasta" class="form-control" :class="{'is-invalid':$v.item.hasta.$error && $v.item.hasta.$dirty}">
                    </div>
                     
                    <div class="form-group col-md-4">
                        <label class="form-label">Prescrito en</label>
                        <input type="text" v-model="item.prescrito_en" class="form-control" :class="{'is-invalid':$v.item.prescrito_en.$error && $v.item.prescrito_en.$dirty}">
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">Problemas</label>
                        <textarea class="form-control" v-model="item.problemas" cols="30" rows="1"></textarea>
                    </div>
                    <div class="mt-4 py-2 col-md-2  ">
                        
                        <button class="btn btn-primary " @click="agregar()">
                            <i class="fas fa-plus"></i>&nbsp;Guardar
                        </button>
                    </div>
                </div>
               
                <div class="table-responsive">
                    <table class="table table-striped">
                        <tr class="table-primary">
                            <th>Metodo </th>
                            <th>Desde</th>
                            <th>Hasta</th>
                            <th>Prescrito en</th>
                            <th>Problemas</th>
                            <th></th>
                        </tr>
                        <tr v-for="item in items" :key="item.id" >
                            <td>{{item.metodo_utilizado}}</td>
                            <td>{{ item.desde }}</td>
                            <td>{{ item.hasta }}</td>
                            <td>{{ item.prescrito_en }}</td>
                            <td>{{ item.problemas }}</td>
                            <td>
                                <button class="btn btn-outline-danger"  @click="eliminar(item.id)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>                                
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import historialAnticoncepcionalService from '../../../../services/historialAnticoncepcionalService';
import {required} from "vuelidate/lib/validators";
import {isEmpty} from 'lodash';

import Toast from '../../../../components/common/utilities/toast';
export default {
    props:['idHistoria','idUsuario'],
     
    data() {
        return{
            item:{
                metodo_utilizado:'',
                desde:'',
                hasta:'',
                prescrito_en:'',
                problemas:'',
            },
            items: [],
        }
    },
    validations (){
        return {
            item:{ 
               metodo_utilizado:{required},
               desde:{required},
               hasta:{required},
               prescrito_en:{required},
            }
            
        }
    },
    methods: {
        async agregar() {

            this.$v.item.$touch();

            if (this.$v.item.$invalid) {
                return;
            }

            this.items.push({...this.item});

            try {
                if (isEmpty(this.items)) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al procesar la solicitud'
                    });
                    return;
                }

                let historial_anticoncepcional = {
                    id_historia:this.idHistoria,  
                    id_usuario:this.idUsuario,
                    metodos: this.item,
                };

                this.LoaderSpinnerShow();
               
                await historialAnticoncepcionalService.store(historial_anticoncepcional);
                

                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

                this.limpiarCampos();
                
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async eliminar(id) {
            try {
                this.LoaderSpinnerShow();
                await historialAnticoncepcionalService.delete(id);
                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos Eliminados con exito'
                });
                let i = this.items.findIndex(x=>x.id === id);
                this.items.splice(i, 1);
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        }, 
        limpiarCampos(){
            this.item={};
        }
    },
    async created() {
        const response=await historialAnticoncepcionalService.showByIdUser(this.idUsuario);
        if(!isEmpty(response.data)){
            this.items = response.data.map(x=>{
                return {
                    id:x.id,
                    metodo_utilizado:x.metodo_utilizado,
                    desde:x.desde,
                    hasta:x.hasta,
                    prescrito_en:x.prescrito_en,
                    problemas:x.problemas,

                }
            });        
        } 
        
    },
}
</script>
