<template>

  <main ref="resumen" id="resumen">
    <div class="py-2"><motivoConsultaShow :idHistoria="idHistoria"></motivoConsultaShow></div>
    <div class="py-2"><examenFisicoShow :idHistoria="idHistoria"></examenFisicoShow></div>
    <div class="py-2"><exploracionFisicaShow :idHistoria="idHistoria"></exploracionFisicaShow></div>
    <div class="py-2"><revisionSistemasShow :idHistoria="idHistoria"></revisionSistemasShow></div>
    <div class="py-2"><ordenClinicaShow :idHistoria="idHistoria"></ordenClinicaShow></div>
    <div class="py-2"><formulaMedicaShow :idHistoria="idHistoria"></formulaMedicaShow></div>
    <div class="py-2"><impresionDxShow :idHistoria="idHistoria"></impresionDxShow></div>
    <div class="py-2"><revisionSistemasShow :idHistoria="idHistoria"></revisionSistemasShow></div>
    <div class="py-2"><reporteExamenesShow :idHistoria="idHistoria"></reporteExamenesShow></div>
    <div class="py-2"><indexHistorialAnticoncepcionalShow :idHistoria="idHistoria" :idUsuario="idUsuario"></indexHistorialAnticoncepcionalShow></div>
    <div class="py-2"><indexEmbarazosAnterioresShow :idHistoria="idHistoria" :idUsuario="idUsuario"></indexEmbarazosAnterioresShow></div>
    <div class="py-2"><indexHistoriaMenstrualShow :idHistoria="idHistoria" :idUsuario="idUsuario"></indexHistoriaMenstrualShow></div>
    <div class="py-2"><intervencionesShow :idHistoria="idHistoria"></intervencionesShow></div>
    <div class="py-2"><recomendacionesShow :idHistoria="idHistoria"></recomendacionesShow></div>
    <div class="py-2"><indexRiesgoReproductivoShow :idHistoria="idHistoria"></indexRiesgoReproductivoShow></div>
  </main>

</template>
<script>

import motivoConsultaShow from "../../base/motivoConsulta/motivoConsultaShow";
import examenFisicoShow from "../../base/examenFisico/examenFisicoShow";
import ordenClinicaShow from "../../base/orden/ordenClinicaShow";
import formulaMedicaShow from "../../base/formulaMedica/formulaMedicaShow";
import impresionDxShow from "../../base/impresionDX/impresionDxShow";
import exploracionFisicaShow from "../../base/examenFisico/exploracionFisicaShow";
import revisionSistemasShow from "../../base/basePartesRevisionSistemas/revisionSistemasShow";

import reporteExamenesShow from "../../base/baseReporteExamenesAdolescente/reporteExamenesShow";
import indexHistorialAnticoncepcionalShow from "../../base/baseHistorialAnticoncepcional/indexHistorialAnticoncepcionalShow";
import indexEmbarazosAnterioresShow from "../../base/baseEmbarazosAnteriores/indexEmbarazosAnterioresShow";
import indexHistoriaMenstrualShow from "../../base/baseHistoriaMenstrual/indexHistoriaMenstrualShow";
import intervencionesShow from "../../base/intervenciones/intervencionesShow";
import recomendacionesShow from "../../base/recomendaciones/recomendacionesShow";
import indexRiesgoReproductivoShow from "../../base/baseRiesgoReproductivo/indexRiesgoReproductivoShow";


export default {
  props: ['idHistoria','idUsuario', 'usuario'],
  components:{
    motivoConsultaShow,
    examenFisicoShow,
    ordenClinicaShow,
    formulaMedicaShow,
    impresionDxShow,
    exploracionFisicaShow,
    revisionSistemasShow,
    reporteExamenesShow,
    indexHistorialAnticoncepcionalShow,indexEmbarazosAnterioresShow,
    indexHistoriaMenstrualShow ,intervencionesShow,recomendacionesShow,indexRiesgoReproductivoShow
  },
}
</script>
<style scoped>
.resumen-container {
  height:300px;
  overflow-y:scroll
}

</style>