<template>
    <main>
        <div class=" " >
            <div class=" ">
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label class="form-label">Fecha de Terminación</label>
                        <input type="date" v-model="item_embarazos_anteriores.fecha_terminacion" class="form-control" :class="{'is-invalid':$v.item_embarazos_anteriores.fecha_terminacion.$error && $v.item_embarazos_anteriores.fecha_terminacion.$dirty}">
                    </div>
                    <div class="form-group col-md-6">
                        <label class="form-label">Meses de Gestación</label>
                        <input type="number" v-model="item_embarazos_anteriores.meses_gestacion" class="form-control" :class="{'is-invalid':$v.item_embarazos_anteriores.meses_gestacion.$error && $v.item_embarazos_anteriores.meses_gestacion.$dirty}">
                    </div>
                     
                    <div class="form-group col-md-4">
                        <label class="form-label">Tipo de Parto</label>
                        <select v-model="item_embarazos_anteriores.tipo_parto" class="form-control" :class="{'is-invalid':$v.item_embarazos_anteriores.tipo_parto.$error && $v.item_embarazos_anteriores.tipo_parto.$dirty}">
                            <option value="CESAREA">CESAREA</option>
                            <option value="ABORTO">ABORTO</option>
                            <option value="PARTO VAGINAL">PARTO VAGINAL</option>
                        </select>
                    </div>
                    <div class="form-group col-md-4">
                        <label class="form-label">Producto</label>
                        <select v-model="item_embarazos_anteriores.producto" class="form-control" :class="{'is-invalid':$v.item_embarazos_anteriores.producto.$error && $v.item_embarazos_anteriores.producto.$dirty}">
                            <option value="MUERTO">MUERTO</option>
                            <option value="VIVO">VIVO</option>
                        </select>
                    </div>
                    <div class="mt-4 py-2 col-md-4  ">
                        
                        <button class="btn btn-primary " @click="agregar()">
                            <i class="fas fa-plus"></i>&nbsp;Guardar
                        </button>
                    </div>
                </div>
               
                <div class="table-responsive">
                    <table class="table table-striped">
                        <tr class="table-primary">
                            <th>Fecha de terminación</th>
                            <th>Meses de gestación</th>
                            <th>Tipo de parto</th>
                            <th>producto</th>
                            <th></th>
                        </tr>
                        <tr v-for="item in items_embarazo" :key="item.id" >
                            <td>{{ item.fecha_terminacion }}</td>
                            <td>{{ item.meses_gestacion }}</td>
                            <td>{{ item.tipo_parto }}</td>
                            <td>{{ item.producto }}</td>
                            <td>
                                <button class="btn btn-outline-danger"  @click="eliminar(item.id)">
                                    <i class="fas fa-trash-alt"></i>
                                </button>
                            </td>
                        </tr>                                
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import embarazosAnterioresService from '../../../../services/embarazosAnterioresService';
import {required} from "vuelidate/lib/validators";
import {isEmpty} from 'lodash';

import Toast from '../../../../components/common/utilities/toast';
export default {
    props:['idHistoria','idUsuario'],
     
    data() {
        return{
            item_embarazos_anteriores:{
                fecha_terminacion:'',
                meses_gestacion:'',
                tipo_parto:'',
                producto:'',
            },
            items_embarazo: [],
        }
    },
    validations (){
        return {
            item_embarazos_anteriores:{ 
               producto:{required},
               fecha_terminacion:{required},
               meses_gestacion:{required},
               tipo_parto:{required},
            }
            
        }
    },
    methods: {
        async agregar() {

            this.$v.item_embarazos_anteriores.$touch();

            if (this.$v.item_embarazos_anteriores.$invalid) {
                return;
            }

            this.items_embarazo.push({...this.item_embarazos_anteriores});

            try {
                if (isEmpty(this.items_embarazo)) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Ocurrio un error al procesar la solicitud'
                    });
                    return;
                }

                let embarazos_anteriores = {
                    id_historia:this.idHistoria,  
                    id_usuario:this.idUsuario,
                    embarazos: this.item_embarazos_anteriores,
                };

                this.LoaderSpinnerShow();
               
                await embarazosAnterioresService.store(embarazos_anteriores);
                

                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

                this.limpiarCampos();
                
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async eliminar(id) {
            try {
                this.LoaderSpinnerShow();
                await embarazosAnterioresService.delete(id);
                this.LoaderSpinnerHide();
                
                Toast.fire({
                    icon: 'success',
                    title: 'Datos Eliminados con exito'
                });
                let i = this.items_embarazo.findIndex(x=>x.id === id);
                this.items_embarazo.splice(i, 1);
            } catch (e) {
                console.error(e);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        }, 
        limpiarCampos(){
            this.item_embarazos_anteriores={};
        }
    },
    async created() {
        const response=await embarazosAnterioresService.showByIdUser(this.idUsuario);
        if(!isEmpty(response.data)){
            this.items_embarazo = response.data.map(x=>{
                return {
                    id:x.id,
                    fecha_terminacion:x.fecha_terminacion,
                    meses_gestacion:x.meses_gestacion,
                    tipo_parto:x.tipo_parto,
                    producto:x.producto,

                }
            });        
        } 
        
    },
}
</script>
