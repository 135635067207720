<template>
    <main>
       <div class=" ">
            <div class="card-header bg-light "><span><i class="fa fa-first-aid"> </i> Embarazos Anteriores</span> </div>
            <div class="card-body">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <tr class="table-primary">
                            <th>Fecha de terminación</th>
                            <th>Meses de gestación</th>
                            <th>Tipo de parto</th>
                            <th>producto</th> 
                        </tr>
                        <tr v-for="item in items_embarazo" :key="item.id" >
                            <td>{{ item.fecha_terminacion }}</td>
                            <td>{{ item.meses_gestacion }}</td>
                            <td>{{ item.tipo_parto }}</td>
                            <td>{{ item.producto }}</td>
                        </tr>                                
                    </table>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import embarazosAnterioresService from '../../../../services/embarazosAnterioresService';
import {isEmpty} from 'lodash';
export default {
    props:['idHistoria','idUsuario'],
     
    data() {
        return{
            item_embarazos_anteriores:{
                fecha_terminacion:'',
                meses_gestacion:'',
                tipo_parto:'',
                producto:'',
            },
            items_embarazo: [],
        }
    },
    async created() {
        const response=await embarazosAnterioresService.showByIdUser(this.idUsuario);
        if(!isEmpty(response.data)){
            this.items_embarazo = response.data.map(x=>{
                return {
                    id:x.id,
                    fecha_terminacion:x.fecha_terminacion,
                    meses_gestacion:x.meses_gestacion,
                    tipo_parto:x.tipo_parto,
                    producto:x.producto,

                }
            });        
        } 
        
    },
}
</script>
