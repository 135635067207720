<template>
    <main>
        <div class=" ">
            <div class="card-header bg-light "><span><i class="fa fa-first-aid"> </i> Historia Menstrual</span> </div>
            <div class="card-body">
                <table class="table table-bordered">
                    <tr>
                        <th>Fecha ultima Menstruacion</th>
                        <td>{{fecha_ultima_menstruacion}}</td>
                    </tr>
                     <tr>
                        <th>Ciclo Regular</th>
                        <td>{{ciclo_regular}}</td>
                    </tr>
                    <tr>
                        <th>Transtornos menstruales</th>
                        <td>{{transtornos_menstruales}}</td>
                    </tr>
                     <tr>
                        <th>Transtorno menstrual</th>
                        <td>{{transtorno_menstrual}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </main>
</template>
<script>
import historialMenstrualService from '../../../../services/historialMenstrualService';
import {isEmpty} from 'lodash';

export default {
    props:['idHistoria','idUsuario','finalizada'],
     
    data() {
        return{
        
            fecha_ultima_menstruacion:'',
            transtornos_menstruales:'',
            transtorno_menstrual:'',
            ciclo_regular:'',
            id_historia_menstrual:'',
            
        }
    },
    async created(){
        
        const response=await historialMenstrualService.showByHc(this.idHistoria);
        if(!isEmpty(response.data)){
            this.fecha_ultima_menstruacion=response.data.fecha_ultima_menstruacion;
            this.transtornos_menstruales=response.data.transtornos_menstruales;
            this.transtorno_menstrual=response.data.transtorno_menstrual;
            this.ciclo_regular=response.data.ciclo_regular;
            this.id_historia_menstrual=response.data.id;
        }
        
    }
}
</script>
